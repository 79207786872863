import React from "react";

import { ReactComponent as IconAfrica } from "../icons/flag/a-flag-africa.svg";
import { ReactComponent as IconChile } from "../icons/flag/a-flag-chile.svg";
import { ReactComponent as IconGermany } from "../icons/flag/a-flag-germany.svg";
import { ReactComponent as IconIndia } from "../icons/flag/a-flag-india.svg";
import { ReactComponent as IconPoland } from "../icons/flag/a-flag-poland.svg";
import { ReactComponent as IconUk } from "../icons/flag/a-flag-uk.svg";
import { ReactComponent as IconUsa } from "../icons/flag/a-flag-usa.svg";
import { ReactComponent as IconAccordionExpand18 } from "../icons/icon/18/a-icon-accordeon-expand-18.svg";
import { ReactComponent as IconAccordionMinimize18 } from "../icons/icon/18/a-icon-accordeon-minimize-18.svg";
import { ReactComponent as IconAnchorLeft18 } from "../icons/icon/18/a-icon-anchor-left-18.svg";
import { ReactComponent as IconAnchorLeftWhite18 } from "../icons/icon/18/a-icon-anchor-left-white-18.svg";
import { ReactComponent as IconAnchorRight18 } from "../icons/icon/18/a-icon-anchor-right-18.svg";
import { ReactComponent as IconAnchorRightWhite18 } from "../icons/icon/18/a-icon-anchor-right-white-18.svg";
import { ReactComponent as IconArticle18 } from "../icons/icon/18/a-icon-article-18.svg";
import { ReactComponent as IconBacteria18 } from "../icons/icon/18/a-icon-bacteria-18.svg";
import { ReactComponent as IconBigHome18 } from "../icons/icon/18/a-icon-bighome-18.svg";
import { ReactComponent as IconBulletPoints18 } from "../icons/icon/18/a-icon-bulletpoint-18.svg";
import { ReactComponent as IconCalendar18 } from "../icons/icon/18/a-icon-calendar-18.svg";
import { ReactComponent as IconCalendarArchive18 } from "../icons/icon/18/a-icon-calendararchive-18.svg";
import { ReactComponent as IconCareers18 } from "../icons/icon/18/a-icon-careers-18.svg";
import { ReactComponent as IconCheck18 } from "../icons/icon/18/a-icon-check-18.svg";
import { ReactComponent as IconCheckbox18 } from "../icons/icon/18/a-icon-checkbox-18.svg";
import { ReactComponent as IconCheckboxError18 } from "../icons/icon/18/a-icon-checkbox-error-18.svg";
import { ReactComponent as IconCheckboxFilled18 } from "../icons/icon/18/a-icon-checkbox-filled-18.svg";
import { ReactComponent as IconClearInput18 } from "../icons/icon/18/a-icon-clearinput-18.svg";
import { ReactComponent as IconClose18 } from "../icons/icon/18/a-icon-close-18.svg";
import { ReactComponent as IconConsentManagement18 } from "../icons/icon/18/a-icon-consentmanagement-18.svg";
import { ReactComponent as IconDashboard18 } from "../icons/icon/18/a-icon-dashboard-18.svg";
import { ReactComponent as IconDefault18 } from "../icons/icon/18/a-icon-default-18.svg";
import { ReactComponent as IconDelete18 } from "../icons/icon/18/a-icon-delete-18.svg";
import { ReactComponent as IconDeleteInput18 } from "../icons/icon/18/a-icon-deleteinput-18.svg";
import { ReactComponent as IconDirectory18 } from "../icons/icon/18/a-icon-directory-18.svg";
import { ReactComponent as IconDollar18 } from "../icons/icon/18/a-icon-dollar-18.svg";
import { ReactComponent as IconDonate18 } from "../icons/icon/18/a-icon-donate-18.svg";
import { ReactComponent as IconDownload18 } from "../icons/icon/18/a-icon-download-18.svg";
import { ReactComponent as IconDropdownExpand18 } from "../icons/icon/18/a-icon-dropdown-expand-18.svg";
import { ReactComponent as IconDropdownMinimize18 } from "../icons/icon/18/a-icon-dropdown-minimize-18.svg";
import { ReactComponent as IconEdit18 } from "../icons/icon/18/a-icon-edit-18.svg";
import { ReactComponent as IconError18 } from "../icons/icon/18/a-icon-error-18.svg";
import { ReactComponent as IconEuro18 } from "../icons/icon/18/a-icon-euro-18.svg";
import { ReactComponent as IconExternalLink18 } from "../icons/icon/18/a-icon-externallink-18.svg";
import { ReactComponent as IconFamily18 } from "../icons/icon/18/a-icon-family-18.svg";
import { ReactComponent as IconFax18 } from "../icons/icon/18/a-icon-fax-18.svg";
import { ReactComponent as IconFurther18 } from "../icons/icon/18/a-icon-further-18.svg";
import { ReactComponent as IconHelp18 } from "../icons/icon/18/a-icon-help-18.svg";
import { ReactComponent as IconHome18 } from "../icons/icon/18/a-icon-home-18.svg";
import { ReactComponent as IconInfo18 } from "../icons/icon/18/a-icon-info-18.svg";
import { ReactComponent as IconJpg18 } from "../icons/icon/18/a-icon-jpg-18.svg";
import { ReactComponent as IconLearnMore18 } from "../icons/icon/18/a-icon-learnmore-18.svg";
import { ReactComponent as IconLocation18 } from "../icons/icon/18/a-icon-location-18.svg";
import { ReactComponent as IconLogin18 } from "../icons/icon/18/a-icon-login-18.svg";
import { ReactComponent as IconLogout18 } from "../icons/icon/18/a-icon-logout-18.svg";
import { ReactComponent as IconMail18 } from "../icons/icon/18/a-icon-mail-18.svg";
import { ReactComponent as IconMaps18 } from "../icons/icon/18/a-icon-maps-18.svg";
import { ReactComponent as IconMenu18 } from "../icons/icon/18/a-icon-menu-18.svg";
import { ReactComponent as IconMp418 } from "../icons/icon/18/a-icon-mp4-18.svg";
import { ReactComponent as IconNews18 } from "../icons/icon/18/a-icon-news-18.svg";
import { ReactComponent as IconPath18 } from "../icons/icon/18/a-icon-path-18.svg";
import { ReactComponent as IconPathBack18 } from "../icons/icon/18/a-icon-pathback-18.svg";
import { ReactComponent as IconPdf18 } from "../icons/icon/18/a-icon-pdf-18.svg";
import { ReactComponent as IconPhone18 } from "../icons/icon/18/a-icon-phone-18.svg";
import { ReactComponent as IconPounds18 } from "../icons/icon/18/a-icon-pounds-18.svg";
import { ReactComponent as IconPrivate18 } from "../icons/icon/18/a-icon-private-18.svg";
import { ReactComponent as IconProfile18 } from "../icons/icon/18/a-icon-profile-18.svg";
import { ReactComponent as IconRadioButton18 } from "../icons/icon/18/a-icon-radiobutton-18.svg";
import { ReactComponent as IconRadioButtonError18 } from "../icons/icon/18/a-icon-radiobutton-error-18.svg";
import { ReactComponent as IconRadioButtonFilled18 } from "../icons/icon/18/a-icon-radiobutton-filled-18.svg";
import { ReactComponent as IconRand18 } from "../icons/icon/18/a-icon-rand-18.svg";
import { ReactComponent as IconRupie18 } from "../icons/icon/18/a-icon-rupie-18.svg";
import { ReactComponent as IconSave18 } from "../icons/icon/18/a-icon-save-18.svg";
import { ReactComponent as IconSearch18 } from "../icons/icon/18/a-icon-search-18.svg";
import { ReactComponent as IconSearchTeaser18 } from "../icons/icon/18/a-icon-searchteaser-18.svg";
import { ReactComponent as IconSnapscan18 } from "../icons/icon/18/a-icon-snapscan-18.svg";
import { ReactComponent as IconSubmit18 } from "../icons/icon/18/a-icon-submit-18.svg";
import { ReactComponent as IconTargetGroups18 } from "../icons/icon/18/a-icon-targetgroups-18.svg";
import { ReactComponent as IconTime18 } from "../icons/icon/18/a-icon-time-18.svg";
import { ReactComponent as IconTopic18 } from "../icons/icon/18/a-icon-topic-18.svg";
import { ReactComponent as IconWebsite18 } from "../icons/icon/18/a-icon-web-18.svg";
import { ReactComponent as IconWebForm18 } from "../icons/icon/18/a-icon-webform-18.svg";
import { ReactComponent as IconZapper18 } from "../icons/icon/18/a-icon-zapper-18.svg";
import { ReactComponent as IconZip18 } from "../icons/icon/18/a-icon-zip-18.svg";
import { ReactComponent as IconZloty18 } from "../icons/icon/18/a-icon-zloty-18.svg";
import { ReactComponent as IconAccessibility24 } from "../icons/icon/24/a-icon-accessibility-24.svg";
import { ReactComponent as IconAdd24 } from "../icons/icon/24/a-icon-add-24.svg";
import { ReactComponent as IconAnchorRight24 } from "../icons/icon/24/a-icon-anchorright-24.svg";
import { ReactComponent as IconCalendar24 } from "../icons/icon/24/a-icon-calendar-24.svg";
import { ReactComponent as IconCalendarArchive24 } from "../icons/icon/24/a-icon-calendararchive-24.svg";
import { ReactComponent as IconCheck24 } from "../icons/icon/24/a-icon-check-24.svg";
import { ReactComponent as IconClose24 } from "../icons/icon/24/a-icon-close-24.svg";
import { ReactComponent as IconCreditCard24 } from "../icons/icon/24/a-icon-credit-card-24.svg";
import { ReactComponent as IconDefault24 } from "../icons/icon/24/a-icon-default-24.svg";
import { ReactComponent as IconDonate24 } from "../icons/icon/24/a-icon-donate-24.svg";
import { ReactComponent as IconDownload24 } from "../icons/icon/24/a-icon-download-24.svg";
import { ReactComponent as IconDrag24 } from "../icons/icon/24/a-icon-drag-24.svg";
import { ReactComponent as IconDropdownExpand24 } from "../icons/icon/24/a-icon-dropdown-expand-24.svg";
import { ReactComponent as IconEarth24 } from "../icons/icon/24/a-icon-earth-24.svg";
import { ReactComponent as IconEdit24 } from "../icons/icon/24/a-icon-edit-24.svg";
import { ReactComponent as IconExpand24 } from "../icons/icon/24/a-icon-expand-24.svg";
import { ReactComponent as IconExternalLink24 } from "../icons/icon/24/a-icon-externallink-24.svg";
import { ReactComponent as IconEyeHide24 } from "../icons/icon/24/a-icon-eye-hide-24.svg";
import { ReactComponent as IconEyeShow24 } from "../icons/icon/24/a-icon-eye-show-24.svg";
import { ReactComponent as IconFamily24 } from "../icons/icon/24/a-icon-family-24.svg";
import { ReactComponent as IconFillForm24 } from "../icons/icon/24/a-icon-fillform-24.svg";
import { ReactComponent as IconFilter24 } from "../icons/icon/24/a-icon-filter-24.svg";
import { ReactComponent as IconFilterApplied24 } from "../icons/icon/24/a-icon-filterapplied-24.svg";
import { ReactComponent as IconFurther24 } from "../icons/icon/24/a-icon-further-24.svg";
import { ReactComponent as IconHelp24 } from "../icons/icon/24/a-icon-help-24.svg";
import { ReactComponent as IconHideHelp24 } from "../icons/icon/24/a-icon-hidehelp-24.svg";
import { ReactComponent as IconInfo24 } from "../icons/icon/24/a-icon-info-24.svg";
import { ReactComponent as IconLink24 } from "../icons/icon/24/a-icon-link-24.svg";
import { ReactComponent as IconList24 } from "../icons/icon/24/a-icon-list-24.svg";
import { ReactComponent as IconLocation24 } from "../icons/icon/24/a-icon-location-24.svg";
import { ReactComponent as IconLogin24 } from "../icons/icon/24/a-icon-login-24.svg";
import { ReactComponent as IconMail24 } from "../icons/icon/24/a-icon-mail-24.svg";
import { ReactComponent as IconMap24 } from "../icons/icon/24/a-icon-map-24.svg";
import { ReactComponent as IconMessage24 } from "../icons/icon/24/a-icon-message-24.svg";
import { ReactComponent as IconMinimize24 } from "../icons/icon/24/a-icon-minimize-24.svg";
import { ReactComponent as IconPhone24 } from "../icons/icon/24/a-icon-phone-24.svg";
import { ReactComponent as IconRetry24 } from "../icons/icon/24/a-icon-retry-24.svg";
import { ReactComponent as IconSave24 } from "../icons/icon/24/a-icon-save-24.svg";
import { ReactComponent as IconSearch24 } from "../icons/icon/24/a-icon-search-24.svg";
import { ReactComponent as IconSelect24 } from "../icons/icon/24/a-icon-select-24.svg";
import { ReactComponent as IconSelectBlue24 } from "../icons/icon/24/a-icon-select-blue-24.svg";
import { ReactComponent as IconSelectEmpty24 } from "../icons/icon/24/a-icon-selectempty-24.svg";
import { ReactComponent as IconSubmit24 } from "../icons/icon/24/a-icon-submit-24.svg";
import { ReactComponent as IconSuccess24 } from "../icons/icon/24/a-icon-success-24.svg";
import { ReactComponent as IconSuccessFilled24 } from "../icons/icon/24/a-icon-success-filled-24.svg";
import {
  ReactComponent as IconTargetGroupAssociations24,
} from "../icons/icon/24/a-icon-targetgroup-associations-24.svg";
import { ReactComponent as IconTargetGroupClub24 } from "../icons/icon/24/a-icon-targetgroup-club-24.svg";
import { ReactComponent as IconTargetGroupCompanies24 } from "../icons/icon/24/a-icon-targetgroup-companies-24.svg";
import { ReactComponent as IconTargetGroupCompany24 } from "../icons/icon/24/a-icon-targetgroup-company-24.svg";
import { ReactComponent as IconTargetGroupDoctors24 } from "../icons/icon/24/a-icon-targetgroup-doctors-24.svg";
import { ReactComponent as IconTargetGroupEducators24 } from "../icons/icon/24/a-icon-targetgroup-educators-24.svg";
import { ReactComponent as IconTargetGroupParents24 } from "../icons/icon/24/a-icon-targetgroup-parents-24.svg";
import { ReactComponent as IconTargetGroupSchool24 } from "../icons/icon/24/a-icon-targetgroup-school-24.svg";
import { ReactComponent as IconTargetGroupSchools24 } from "../icons/icon/24/a-icon-targetgroup-schools-24.svg";
import { ReactComponent as IconTargetGroupSportsClubs24 } from "../icons/icon/24/a-icon-targetgroup-sportsclubs-24.svg";
import { ReactComponent as IconTargetGroupStudents24 } from "../icons/icon/24/a-icon-targetgroup-students-24.svg";
import { ReactComponent as IconTargetGroupVolunteers24 } from "../icons/icon/24/a-icon-targetgroup-volunteers-24.svg";
import { ReactComponent as IconTextFile24 } from "../icons/icon/24/a-icon-textfile-24.svg";
import { ReactComponent as IconTime24 } from "../icons/icon/24/a-icon-time-24.svg";
import { ReactComponent as IconUndo24 } from "../icons/icon/24/a-icon-undo-24.svg";
import { ReactComponent as IconUpdate24 } from "../icons/icon/24/a-icon-update-24.svg";
import { ReactComponent as IconWarning24 } from "../icons/icon/24/a-icon-warning-24.svg";
import { ReactComponent as IconWebForm24 } from "../icons/icon/24/a-icon-webform-24.svg";
import { ReactComponent as IconZoom24 } from "../icons/icon/24/a-icon-zoom-24.svg";
import { ReactComponent as IconAddDonor30 } from "../icons/icon/30/a-icon-adddonor-30.svg";
import { ReactComponent as IconBloodSample30 } from "../icons/icon/30/a-icon-bloodsample-30.svg";
import { ReactComponent as IconBoneMarrowCollection30 } from "../icons/icon/30/a-icon-bonemarrowcollection-30.svg";
import { ReactComponent as IconCar30 } from "../icons/icon/30/a-icon-car-30.svg";
import { ReactComponent as IconCharity30 } from "../icons/icon/30/a-icon-charity-30.svg";
import { ReactComponent as IconCharityEvent30 } from "../icons/icon/30/a-icon-charityevent-30.svg";
import { ReactComponent as IconCheckUp30 } from "../icons/icon/30/a-icon-checkup-30.svg";
import { ReactComponent as IconChildren30 } from "../icons/icon/30/a-icon-children-30.svg";
import { ReactComponent as IconConfirmatoryPing30 } from "../icons/icon/30/a-icon-confirmatorytyping-30.svg";
import { ReactComponent as IconCooperation30 } from "../icons/icon/30/a-icon-cooperation-30.svg";
import { ReactComponent as IconDefault30 } from "../icons/icon/30/a-icon-default-30.svg";
import { ReactComponent as IconDnaHla30 } from "../icons/icon/30/a-icon-dnahla-30.svg";
import { ReactComponent as IconDonateDe30 } from "../icons/icon/30/a-icon-donate-de-30.svg";
import { ReactComponent as IconDonateIn30 } from "../icons/icon/30/a-icon-donate-in-30.svg";
import { ReactComponent as IconDonatePl30 } from "../icons/icon/30/a-icon-donate-pl-30.svg";
import { ReactComponent as IconDonateUk30 } from "../icons/icon/30/a-icon-donate-uk-30.svg";
import { ReactComponent as IconDonateUsCl30 } from "../icons/icon/30/a-icon-donate-uscl-30.svg";
import { ReactComponent as IconDonor30 } from "../icons/icon/30/a-icon-donor-30.svg";
import { ReactComponent as IconDonorDrive30 } from "../icons/icon/30/a-icon-donordrive-30.svg";
import { ReactComponent as IconDonorPatientMeeting30 } from "../icons/icon/30/a-icon-donorpatientmeeting-30.svg";
import { ReactComponent as IconEvent30 } from "../icons/icon/30/a-icon-event-30.svg";
import { ReactComponent as IconEventHosting30 } from "../icons/icon/30/a-icon-eventhosting-30.svg";
import { ReactComponent as IconFamilySearch30 } from "../icons/icon/30/a-icon-familysearch-30.svg";
import { ReactComponent as IconFaq30 } from "../icons/icon/30/a-icon-faq-30.svg";
import { ReactComponent as IconFilingOrganisation30 } from "../icons/icon/30/a-icon-filingorganisation-30.svg";
import { ReactComponent as IconFounding30 } from "../icons/icon/30/a-icon-founding-30.svg";
import { ReactComponent as IconGlobalDonors30 } from "../icons/icon/30/a-icon-globaldonors-30.svg";
import { ReactComponent as IconGlobalSearch30 } from "../icons/icon/30/a-icon-globalsearch-30.svg";
import { ReactComponent as IconHappyDonor30 } from "../icons/icon/30/a-icon-happydonor-30.svg";
import { ReactComponent as IconHappyPatient30 } from "../icons/icon/30/a-icon-happypatient-30.svg";
import { ReactComponent as IconHouse30 } from "../icons/icon/30/a-icon-house-30.svg";
import { ReactComponent as IconIdmRequest30 } from "../icons/icon/30/a-icon-idmrequest-30.svg";
import { ReactComponent as IconImportant30 } from "../icons/icon/30/a-icon-important-30.svg";
import { ReactComponent as IconInfluencer30 } from "../icons/icon/30/a-icon-influencer-30.svg";
import { ReactComponent as IconInfo30 } from "../icons/icon/30/a-icon-info-30.svg";
import { ReactComponent as IconLaunch30 } from "../icons/icon/30/a-icon-launch-30.svg";
import { ReactComponent as IconLocalDonors30 } from "../icons/icon/30/a-icon-localdonors-30.svg";
import { ReactComponent as IconMatch30 } from "../icons/icon/30/a-icon-match-30.svg";
import { ReactComponent as IconMediaClue30 } from "../icons/icon/30/a-icon-mediaclue-30.svg";
import { ReactComponent as IconNews30 } from "../icons/icon/30/a-icon-news-30.svg";
import { ReactComponent as IconNotification30 } from "../icons/icon/30/a-icon-notification-30.svg";
import { ReactComponent as IconOnlineRegistration30 } from "../icons/icon/30/a-icon-onlineregistration-30.svg";
import { ReactComponent as IconPaperWork30 } from "../icons/icon/30/a-icon-paperwork-30.svg";
import { ReactComponent as IconPatient30 } from "../icons/icon/30/a-icon-patient-30.svg";
import { ReactComponent as IconPreparationDli30 } from "../icons/icon/30/a-icon-preparationdli-30.svg";
import { ReactComponent as IconRadio30 } from "../icons/icon/30/a-icon-radio-30.svg";
import { ReactComponent as IconRegistration30 } from "../icons/icon/30/a-icon-registration-30.svg";
import { ReactComponent as IconRegistrationCosts30 } from "../icons/icon/30/a-icon-registrationcosts-30.svg";
import { ReactComponent as IconRegistrationProgress30 } from "../icons/icon/30/a-icon-registrationprogress-30.svg";
import { ReactComponent as IconRegistrationViaBlood30 } from "../icons/icon/30/a-icon-registrationviablood-30.svg";
import { ReactComponent as IconRegistryEntry30 } from "../icons/icon/30/a-icon-registryentry-30.svg";
import { ReactComponent as IconSadPatient30 } from "../icons/icon/30/a-icon-sadpatient-30.svg";
import { ReactComponent as IconSendingBack30 } from "../icons/icon/30/a-icon-sendingback-30.svg";
import { ReactComponent as IconStemCellCollection30 } from "../icons/icon/30/a-icon-stemcellcollection-30.svg";
import { ReactComponent as IconStop30 } from "../icons/icon/30/a-icon-stop-30.svg";
import { ReactComponent as IconSwabbing30 } from "../icons/icon/30/a-icon-swabbing-30.svg";
import { ReactComponent as IconThumbsUp30 } from "../icons/icon/30/a-icon-thumbsup-30.svg";
import { ReactComponent as IconTick30 } from "../icons/icon/30/a-icon-tick-30.svg";
import { ReactComponent as IconTickGreen30 } from "../icons/icon/30/a-icon-tick-green-30.svg";
import { ReactComponent as IconTime30 } from "../icons/icon/30/a-icon-time-30.svg";
import { ReactComponent as IconTimeframe30 } from "../icons/icon/30/a-icon-timeframe-30.svg";
import { ReactComponent as IconTransplantation30 } from "../icons/icon/30/a-icon-transplantation-30.svg";
import { ReactComponent as IconUpdates30 } from "../icons/icon/30/a-icon-updates-30.svg";
import { ReactComponent as IconUserStory30 } from "../icons/icon/30/a-icon-userstory-30.svg";
import { ReactComponent as IconVerbalContact30 } from "../icons/icon/30/a-icon-verbalcontact-30.svg";
import { ReactComponent as IconVirtualDrive30 } from "../icons/icon/30/a-icon-virtualdrive-30.svg";
import { ReactComponent as IconWorkUp30 } from "../icons/icon/30/a-icon-workup-30.svg";
import { ReactComponent as IconWrittenContact30 } from "../icons/icon/30/a-icon-writtencontact-30.svg";
import { ReactComponent as IconCooperation48 } from "../icons/icon/48/a-icon-cooperation-48.svg";
import { ReactComponent as IconDefault48 } from "../icons/icon/48/a-icon-default-48.svg";
import { ReactComponent as IconDnaHla48 } from "../icons/icon/48/a-icon-dnahla-48.svg";
import { ReactComponent as IconDualToneAssets48 } from "../icons/icon/48/a-icon-dualtone-assets-48.svg";
import { ReactComponent as IconDualToneAssociations48 } from "../icons/icon/48/a-icon-dualtone-associations-48.svg";
import {
  ReactComponent as IconDualToneBackgroundEarth48,
} from "../icons/icon/48/a-icon-dualtone-backgroundearth-48.svg";
import { ReactComponent as IconDualToneBioInformatics48 } from "../icons/icon/48/a-icon-dualtone-bioinformatics-48.svg";
import { ReactComponent as IconDualToneCharityEvent48 } from "../icons/icon/48/a-icon-dualtone-charityevent-48.svg";
import { ReactComponent as IconDualToneClinicalLab48 } from "../icons/icon/48/a-icon-dualtone-clinicallab-48.svg";
import { ReactComponent as IconDualToneCollaboration48 } from "../icons/icon/48/a-icon-dualtone-collaboration-48.svg";
import {
  ReactComponent as IconDualToneCommercialDepartment48,
} from "../icons/icon/48/a-icon-dualtone-commercialdepartment-48.svg";
import { ReactComponent as IconDualToneCompanies48 } from "../icons/icon/48/a-icon-dualtone-companies-48.svg";
import { ReactComponent as IconDualToneContact48 } from "../icons/icon/48/a-icon-dualtone-contact-48.svg";
import { ReactComponent as IconDualToneCooperation48 } from "../icons/icon/48/a-icon-dualtone-cooperation-48.svg";
import {
  ReactComponent as IconDualToneCorporateQuality48,
} from "../icons/icon/48/a-icon-dualtone-corporatequality-48.svg";
import { ReactComponent as IconDualToneDataProtection48 } from "../icons/icon/48/a-icon-dualtone-dataprotection-48.svg";
import { ReactComponent as IconDualToneDepartments48 } from "../icons/icon/48/a-icon-dualtone-departments-48.svg";
import { ReactComponent as IconDualToneDoctors48 } from "../icons/icon/48/a-icon-dualtone-doctors-48.svg";
import { ReactComponent as IconDualToneDocuments48 } from "../icons/icon/48/a-icon-dualtone-documents-48.svg";
import { ReactComponent as IconDualToneDonateAfr48 } from "../icons/icon/48/a-icon-dualtone-donate-afr-48.svg";
import { ReactComponent as IconDualToneDonateDe48 } from "../icons/icon/48/a-icon-dualtone-donate-de-48.svg";
import { ReactComponent as IconDualToneDonateIn48 } from "../icons/icon/48/a-icon-dualtone-donate-in-48.svg";
import { ReactComponent as IconDualToneDonatePl48 } from "../icons/icon/48/a-icon-dualtone-donate-pl-48.svg";
import { ReactComponent as IconDualToneDonateUk48 } from "../icons/icon/48/a-icon-dualtone-donate-uk-48.svg";
import { ReactComponent as IconDualToneDonateUsCl48 } from "../icons/icon/48/a-icon-dualtone-donate-uscl-48.svg";
import { ReactComponent as IconDualToneDonorDrive48 } from "../icons/icon/48/a-icon-dualtone-donordrive-48.svg";
import { ReactComponent as IconDualToneDonorSuccess48 } from "../icons/icon/48/a-icon-dualtone-donorsuccess-48.svg";
import { ReactComponent as IconDualToneEarth48 } from "../icons/icon/48/a-icon-dualtone-earth-48.svg";
import { ReactComponent as IconDualToneEducators48 } from "../icons/icon/48/a-icon-dualtone-educators-48.svg";
import { ReactComponent as IconDualToneEvent48 } from "../icons/icon/48/a-icon-dualtone-event-48.svg";
import { ReactComponent as IconDualToneEventHosting48 } from "../icons/icon/48/a-icon-dualtone-eventhosting-48.svg";
import { ReactComponent as IconDualToneFactsFigures48 } from "../icons/icon/48/a-icon-dualtone-factsfigures-48.svg";
import { ReactComponent as IconDualToneFactSheet48 } from "../icons/icon/48/a-icon-dualtone-factsheet-48.svg";
import { ReactComponent as IconDualToneFAQ48 } from "../icons/icon/48/a-icon-dualtone-faq-48.svg";
import {
  ReactComponent as IconDualToneGenotypingAnalysis48,
} from "../icons/icon/48/a-icon-dualtone-genotypinganalysis-48.svg";
import { ReactComponent as IconDualToneGlossary48 } from "../icons/icon/48/a-icon-dualtone-glossary-48.svg";
import { ReactComponent as IconDualToneInfluencer48 } from "../icons/icon/48/a-icon-dualtone-influencer-48.svg";
import {
  ReactComponent as IconDualToneItServicesAndSolutions48,
} from "../icons/icon/48/a-icon-dualtone-itservicesandsolutions-48.svg";
import { ReactComponent as IconDualToneLabManagement48 } from "../icons/icon/48/a-icon-dualtone-labmanagement-48.svg";
import { ReactComponent as IconDualToneMaterial48 } from "../icons/icon/48/a-icon-dualtone-material-48.svg";
import IconDualToneMolecularInfectionDiagnostic48
  from "../icons/icon/48/a-icon-dualtone-molecularinfectiondiagnostic-48.svg";
import { ReactComponent as IconDualtoneNewbadge48 } from "../icons/icon/48/a-icon-dualtone-newbadge-48.svg";
import { ReactComponent as IconDualToneParents48 } from "../icons/icon/48/a-icon-dualtone-parents-48.svg";
import { ReactComponent as IconDualTonePostbox48 } from "../icons/icon/48/a-icon-dualtone-postbox-48.svg";
import { ReactComponent as IconDualTonePricing48 } from "../icons/icon/48/a-icon-dualtone-pricing-48.svg";
import { ReactComponent as IconDualToneProfile48 } from "../icons/icon/48/a-icon-dualtone-profile-48.svg";
import { ReactComponent as IconDualTonePublication48 } from "../icons/icon/48/a-icon-dualtone-publication-48.svg";
import { ReactComponent as IconDualToneRegister48 } from "../icons/icon/48/a-icon-dualtone-register-48.svg";
import {
  ReactComponent as IconDualToneResearchAndDevelopment48,
} from "../icons/icon/48/a-icon-dualtone-researchanddevelopment-48.svg";
import {
  ReactComponent as IconDualToneResearchFunding48,
} from "../icons/icon/48/a-icon-dualtone-researchfunding-48.svg";
import { ReactComponent as IconDualToneResearchScope48 } from "../icons/icon/48/a-icon-dualtone-researchscope-48.svg";
import { ReactComponent as IconDualToneResources48 } from "../icons/icon/48/a-icon-dualtone-resources-48.svg";
import { ReactComponent as IconDualToneSchools48 } from "../icons/icon/48/a-icon-dualtone-schools-48.svg";
import { ReactComponent as IconDualToneScienceAward48 } from "../icons/icon/48/a-icon-dualtone-scienceaward-48.svg";
import {
  ReactComponent as IconDualToneScientificPosters48,
} from "../icons/icon/48/a-icon-dualtone-scientificposters-48.svg";
import { ReactComponent as IconDualToneSearchRequest48 } from "../icons/icon/48/a-icon-dualtone-searchrequest-48.svg";
import { ReactComponent as IconDualToneServices48 } from "../icons/icon/48/a-icon-dualtone-services-48.svg";
import { ReactComponent as IconDualToneSportsClubs48 } from "../icons/icon/48/a-icon-dualtone-sportsclubs-48.svg";
import { ReactComponent as IconDualToneStandards48 } from "../icons/icon/48/a-icon-dualtone-standards-48.svg";
import { ReactComponent as IconDualToneStudents48 } from "../icons/icon/48/a-icon-dualtone-students-48.svg";
import { ReactComponent as IconDualToneStudy48 } from "../icons/icon/48/a-icon-dualtone-study-48.svg";
import {
  ReactComponent as IconDualToneSupportPrograms48,
} from "../icons/icon/48/a-icon-dualtone-supportprograms-48.svg";
import { ReactComponent as IconDualToneTools48 } from "../icons/icon/48/a-icon-dualtone-tools-48.svg";
import { ReactComponent as IconDualToneVirtualDrive48 } from "../icons/icon/48/a-icon-dualtone-virtualdrive-48.svg";
import { ReactComponent as IconDualToneVolunteers48 } from "../icons/icon/48/a-icon-dualtone-volunteers-48.svg";
import { ReactComponent as IconDualToneWebform48 } from "../icons/icon/48/a-icon-dualtone-webform-48.svg";
import { ReactComponent as IconEvent48 } from "../icons/icon/48/a-icon-event-48.svg";
import { ReactComponent as IconFounding48 } from "../icons/icon/48/a-icon-founding-48.svg";
import { ReactComponent as IconGobalDonors48 } from "../icons/icon/48/a-icon-gobaldonors-48.svg";
import { ReactComponent as IconHappyPatient48 } from "../icons/icon/48/a-icon-happypatient-48.svg";
import { ReactComponent as IconHostEvent48 } from "../icons/icon/48/a-icon-hostevent-48.svg";
import { ReactComponent as IconLocalDonors48 } from "../icons/icon/48/a-icon-localdonors-48.svg";
import { ReactComponent as IconMatch48 } from "../icons/icon/48/a-icon-match-48.svg";
import { ReactComponent as IconPlay48 } from "../icons/icon/48/a-icon-play-48.svg";
import { ReactComponent as IconRegistration48 } from "../icons/icon/48/a-icon-registration-48.svg";
import { ReactComponent as IconRegistrationCosts48 } from "../icons/icon/48/a-icon-registrationcosts-48.svg";
import { ReactComponent as IconRegistryEntry48 } from "../icons/icon/48/a-icon-registryentry-48.svg";
import { ReactComponent as IconStemCellCollection48 } from "../icons/icon/48/a-icon-stemcellcollection-48.svg";
import { ReactComponent as IconSwabbing48 } from "../icons/icon/48/a-icon-swabbing-48.svg";
import { ReactComponent as IconTransplantation48 } from "../icons/icon/48/a-icon-transplantation-48.svg";

/* eslint-disable sort-keys */
const iconHelper = (iconName) => {
  const icons = {
    icon_register: <IconCooperation48 />,
    icon_stem_cell_collection: <IconStemCellCollection48 />,

    // 18
    icon_accordion_expand_18: <IconAccordionExpand18 />,
    icon_accordion_minimize_18: <IconAccordionMinimize18 />,
    icon_anchor_left_18: <IconAnchorLeft18 />,
    icon_anchor_left_white_18: <IconAnchorLeftWhite18 />,
    icon_anchor_right_18: <IconAnchorRight18 />,
    icon_anchor_right_white_18: <IconAnchorRightWhite18 />,
    icon_article_18: <IconArticle18 />,
    icon_bacteria_18: <IconBacteria18 />,
    icon_big_home_18: <IconBigHome18 />,
    icon_bullet_points_18: <IconBulletPoints18 />,
    icon_calendar_18: <IconCalendar18 />,
    icon_calendar_archive_18: <IconCalendarArchive18 />,
    icon_careers_18: <IconCareers18 />,
    icon_check_18: <IconCheck18 />,
    icon_checkbox_18: <IconCheckbox18 />,
    icon_checkbox_error_18: <IconCheckboxError18 />,
    icon_checkbox_filled_18: <IconCheckboxFilled18 />,
    icon_clear_input_18: <IconClearInput18 />,
    icon_close_18: <IconClose18 />,
    icon_consent_management_18: <IconConsentManagement18 />,
    icon_dashboard_18: <IconDashboard18 />,
    icon_default_18: <IconDefault18 />,
    icon_delete_18: <IconDelete18 />,
    icon_delete_input_18: <IconDeleteInput18 />,
    icon_directory_18: <IconDirectory18 />,
    icon_dollar_18: <IconDollar18 />,
    icon_donate_18: <IconDonate18 />,
    icon_download_18: <IconDownload18 />,
    icon_dropdown_expand_18: <IconDropdownExpand18 />,
    icon_dropdown_minimize_18: <IconDropdownMinimize18 />,
    icon_edit_18: <IconEdit18 />,
    icon_error_18: <IconError18 />,
    icon_euro_18: <IconEuro18 />,
    icon_external_link_18: <IconExternalLink18 />,
    icon_family_18: <IconFamily18 />,
    icon_fax_18: <IconFax18 />,
    icon_further_18: <IconFurther18 />,
    icon_help_18: <IconHelp18 />,
    icon_home_18: <IconHome18 />,
    icon_info_18: <IconInfo18 />,
    icon_jpg_18: <IconJpg18 />,
    icon_learn_more_18: <IconLearnMore18 />,
    icon_location_18: <IconLocation18 />,
    icon_login_18: <IconLogin18 />,
    icon_logout_18: <IconLogout18 />,
    icon_mail_18: <IconMail18 />,
    icon_maps_18: <IconMaps18 />,
    icon_menu_18: <IconMenu18 />,
    icon_mp_418: <IconMp418 />,
    icon_news_18: <IconNews18 />,
    icon_path_18: <IconPath18 />,
    icon_path_back_18: <IconPathBack18 />,
    icon_pdf_18: <IconPdf18 />,
    icon_phone_18: <IconPhone18 />,
    icon_pounds_18: <IconPounds18 />,
    icon_private_18: <IconPrivate18 />,
    icon_profile_18: <IconProfile18 />,
    icon_radio_button_18: <IconRadioButton18 />,
    icon_radio_button_error_18: <IconRadioButtonError18 />,
    icon_radio_button_filled_18: <IconRadioButtonFilled18 />,
    icon_rand_18: <IconRand18 />,
    icon_rupie_18: <IconRupie18 />,
    icon_save_18: <IconSave18 />,
    icon_search_18: <IconSearch18 />,
    icon_search_teaser_18: <IconSearchTeaser18 />,
    icon_snap_scan_18: <IconSnapscan18 />,
    icon_submit_18: <IconSubmit18 />,
    icon_target_groups_18: <IconTargetGroups18 />,
    icon_time_18: <IconTime18 />,
    icon_topic_18: <IconTopic18 />,
    icon_web_18: <IconWebsite18 />,
    icon_web_form_18: <IconWebForm18 />,
    icon_zapper_18: <IconZapper18 />,
    icon_zip_18: <IconZip18 />,
    icon_zloty_18: <IconZloty18 />,
    pdf: <IconPdf18 />,
    zip_archive: <IconZip18 />,

    // 24
    icon_accessibility_24: <IconAccessibility24 />,
    icon_add_24: <IconAdd24 />,
    icon_anchor_right_24: <IconAnchorRight24 />,
    icon_calendar_24: <IconCalendar24 />,
    icon_calendar_archive_24: <IconCalendarArchive24 />,
    icon_check_24: <IconCheck24 />,
    icon_close_24: <IconClose24 />,
    icon_credit_card_24: <IconCreditCard24 />,
    icon_default_24: <IconDefault24 />,
    icon_donate_24: <IconDonate24 />,
    icon_download_24: <IconDownload24 />,
    icon_drag_24: <IconDrag24 />,
    icon_dropdown_expand_24: <IconDropdownExpand24 />,
    icon_earth_24: <IconEarth24 />,
    icon_edit_24: <IconEdit24 />,
    icon_expand_24: <IconExpand24 />,
    icon_external_link_24: <IconExternalLink24 />,
    icon_eye_hide_24: <IconEyeHide24 />,
    icon_eye_show_24: <IconEyeShow24 />,
    icon_family_24: <IconFamily24 />,
    icon_fill_form_24: <IconFillForm24 />,
    icon_filter_24: <IconFilter24 />,
    icon_filter_applied_24: <IconFilterApplied24 />,
    icon_further_24: <IconFurther24 />,
    icon_help_24: <IconHelp24 />,
    icon_hide_help_24: <IconHideHelp24 />,
    icon_info_24: <IconInfo24 />,
    icon_link_24: <IconLink24 />,
    icon_list_24: <IconList24 />,
    icon_location_24: <IconLocation24 />,
    icon_login_24: <IconLogin24 />,
    icon_mail_24: <IconMail24 />,
    icon_map_24: <IconMap24 />,
    icon_message_24: <IconMessage24 />,
    icon_minimize_24: <IconMinimize24 />,
    icon_phone_24: <IconPhone24 />,
    icon_retry_24: <IconRetry24 />,
    icon_save_24: <IconSave24 />,
    icon_search_24: <IconSearch24 />,
    icon_select_24: <IconSelect24 />,
    icon_select_blue_24: <IconSelectBlue24 />,
    icon_select_empty_24: <IconSelectEmpty24 />,
    icon_submit_24: <IconSubmit24 />,
    icon_success_24: <IconSuccess24 />,
    icon_success_filled_24: <IconSuccessFilled24 />,
    icon_target_group_associations_24: <IconTargetGroupAssociations24 />,
    icon_target_group_club_24: <IconTargetGroupClub24 />,
    icon_target_group_companies_24: <IconTargetGroupCompanies24 />,
    icon_target_group_company_24: <IconTargetGroupCompany24 />,
    icon_target_group_doctors_24: <IconTargetGroupDoctors24 />,
    icon_target_group_educators_24: <IconTargetGroupEducators24 />,
    icon_target_group_parents_24: <IconTargetGroupParents24 />,
    icon_target_group_school_24: <IconTargetGroupSchool24 />,
    icon_target_group_schools_24: <IconTargetGroupSchools24 />,
    icon_target_group_sports_clubs_24: <IconTargetGroupSportsClubs24 />,
    icon_target_group_students_24: <IconTargetGroupStudents24 />,
    icon_target_group_volunteers_24: <IconTargetGroupVolunteers24 />,
    icon_textfile_24: <IconTextFile24 />,
    icon_time_24: <IconTime24 />,
    icon_undo_24: <IconUndo24 />,
    icon_update_24: <IconUpdate24 />,
    icon_warning_24: <IconWarning24 />,
    icon_web_form_24: <IconWebForm24 />,
    icon_zoom_24: <IconZoom24 />,

    icon_cobi_24: <img width={24} height={24} src="/images/a-logo-cobi-24.png" alt="dkms cobi logo" />,
    icon_dkms_24: <img width={24} height={24} src="/images/a-logo-dkms-24.png" alt="dkms logo" />,
    icon_dkms_life_24: <img width={24} height={24} src="/images/a-logo-dkmslife-24.png" alt="dkms life logo" />,

    // 30
    icon_add_donor_30: <IconAddDonor30 />,
    icon_blood_sample_30: <IconBloodSample30 />,
    icon_bone_marrow_collection_30: <IconBoneMarrowCollection30 />,
    icon_car_30: <IconCar30 />,
    icon_charity_30: <IconCharity30 />,
    icon_charity_event_30: <IconCharityEvent30 />,
    icon_check_up_30: <IconCheckUp30 />,
    icon_children_30: <IconChildren30 />,
    icon_confirmatory_ping_30: <IconConfirmatoryPing30 />,
    icon_cooperation_30: <IconCooperation30 />,
    icon_default_30: <IconDefault30 />,
    icon_dna_hla_30: <IconDnaHla30 />,
    icon_donate_de_30: <IconDonateDe30 />,
    icon_donate_in_30: <IconDonateIn30 />,
    icon_donate_pl_30: <IconDonatePl30 />,
    icon_donate_uk_30: <IconDonateUk30 />,
    icon_donate_us_cl_30: <IconDonateUsCl30 />,
    icon_donor_30: <IconDonor30 />,
    icon_donor_drive_30: <IconDonorDrive30 />,
    icon_donor_patient_meeting_30: <IconDonorPatientMeeting30 />,
    icon_event_30: <IconEvent30 />,
    icon_event_hosting_30: <IconEventHosting30 />,
    icon_family_search_30: <IconFamilySearch30 />,
    icon_faq_30: <IconFaq30 />,
    icon_filing_organisation_30: <IconFilingOrganisation30 />,
    icon_founding_30: <IconFounding30 />,
    icon_global_donors_30: <IconGlobalDonors30 />,
    icon_global_search_30: <IconGlobalSearch30 />,
    icon_happy_donor_30: <IconHappyDonor30 />,
    icon_happy_patient_30: <IconHappyPatient30 />,
    icon_house_30: <IconHouse30 />,
    icon_idm_request_30: <IconIdmRequest30 />,
    icon_important_30: <IconImportant30 />,
    icon_influencer_30: <IconInfluencer30 />,
    icon_info_30: <IconInfo30 />,
    icon_launch_30: <IconLaunch30 />,
    icon_local_donors_30: <IconLocalDonors30 />,
    icon_match_30: <IconMatch30 />,
    icon_media_clue_30: <IconMediaClue30 />,
    icon_news_30: <IconNews30 />,
    icon_notification_30: <IconNotification30 />,
    icon_online_registration_30: <IconOnlineRegistration30 />,
    icon_paper_work_30: <IconPaperWork30 />,
    icon_patient_30: <IconPatient30 />,
    icon_preparation_dli_30: <IconPreparationDli30 />,
    icon_radio_30: <IconRadio30 />,
    icon_registration_30: <IconRegistration30 />,
    icon_registration_costs_30: <IconRegistrationCosts30 />,
    icon_registrationprogress_30: <IconRegistrationProgress30 />,
    icon_registration_via_blood_30: <IconRegistrationViaBlood30 />,
    icon_registry_entry_30: <IconRegistryEntry30 />,
    icon_sad_patient_30: <IconSadPatient30 />,
    icon_sending_back_30: <IconSendingBack30 />,
    icon_stem_cell_collection_30: <IconStemCellCollection30 />,
    icon_stop_30: <IconStop30 />,
    icon_swabbing_30: <IconSwabbing30 />,
    icon_thumbs_up_30: <IconThumbsUp30 />,
    icon_tick_30: <IconTick30 />,
    icon_tick_green_30: <IconTickGreen30 />,
    icon_time_30: <IconTime30 />,
    icon_time24_30: <IconTimeframe30 />,
    icon_transplantation_30: <IconTransplantation30 />,
    icon_updates_30: <IconUpdates30 />,
    icon_user_story_30: <IconUserStory30 />,
    icon_verbal_contact_30: <IconVerbalContact30 />,
    icon_virtual_drive_30: <IconVirtualDrive30 />,
    icon_work_up_30: <IconWorkUp30 />,
    icon_written_contact_30: <IconWrittenContact30 />,

    icon_registryentry_30: <IconRegistryEntry30 />,
    icon_donate_us_30: <IconDonateUsCl30 />,
    icon_donate_cl_30: <IconDonateUsCl30 />,

    // 48
    icon_cooperation_48: <IconCooperation48 />,
    icon_default_48: <IconDefault48 />,
    icon_dna_hla_48: <IconDnaHla48 />,
    icon_dual_tone_assets_48: <IconDualToneAssets48 />,
    icon_dual_tone_associations_48: <IconDualToneAssociations48 />,
    icon_dual_tone_background_earth_48: <IconDualToneBackgroundEarth48 />,
    icon_dual_tone_bio_informatics_48: <IconDualToneBioInformatics48 />,
    icon_dual_tone_charity_event_48: <IconDualToneCharityEvent48 />,
    icon_dual_tone_clinical_lab_48: <IconDualToneClinicalLab48 />,
    icon_dual_tone_collaboration_48: <IconDualToneCollaboration48 />,
    icon_dual_tone_commercial_department_48: <IconDualToneCommercialDepartment48 />,
    icon_dual_tone_companies_48: <IconDualToneCompanies48 />,
    icon_dual_tone_contact_48: <IconDualToneContact48 />,
    icon_dual_tone_cooperation_48: <IconDualToneCooperation48 />,
    icon_dual_tone_corporate_quality_48: <IconDualToneCorporateQuality48 />,
    icon_dual_tone_data_protection_48: <IconDualToneDataProtection48 />,
    icon_dual_tone_departments_48: <IconDualToneDepartments48 />,
    icon_dual_tone_doctors_48: <IconDualToneDoctors48 />,
    icon_dual_tone_documents_48: <IconDualToneDocuments48 />,
    icon_dual_tone_donate_afr_48: <IconDualToneDonateAfr48 />,
    icon_dual_tone_donate_de_48: <IconDualToneDonateDe48 />,
    icon_dual_tone_donate_in_48: <IconDualToneDonateIn48 />,
    icon_dual_tone_donate_pl_48: <IconDualToneDonatePl48 />,
    icon_dual_tone_donate_uk_48: <IconDualToneDonateUk48 />,
    icon_dual_tone_donate_us_cl_48: <IconDualToneDonateUsCl48 />,
    icon_dual_tone_donor_drive_48: <IconDualToneDonorDrive48 />,
    icon_dual_tone_donor_success_48: <IconDualToneDonorSuccess48 />,
    icon_dual_tone_earth_48: <IconDualToneEarth48 />,
    icon_dual_tone_educators_48: <IconDualToneEducators48 />,
    icon_dual_tone_event_48: <IconDualToneEvent48 />,
    icon_dual_tone_event_hosting_48: <IconDualToneEventHosting48 />,
    icon_dual_tone_facts_figures_48: <IconDualToneFactsFigures48 />,
    icon_dual_tone_fact_sheet_48: <IconDualToneFactSheet48 />,
    icon_dual_tone_faq_48: <IconDualToneFAQ48 />,
    icon_dual_tone_genotyping_analysis_48: <IconDualToneGenotypingAnalysis48 />,
    icon_dual_tone_glossary_48: <IconDualToneGlossary48 />,
    icon_dual_tone_influencer_48: <IconDualToneInfluencer48 />,
    icon_dual_tone_it_services_and_solutions_48: <IconDualToneItServicesAndSolutions48 />,
    icon_dual_tone_lab_management_48: <IconDualToneLabManagement48 />,
    icon_dual_tone_material_48: <IconDualToneMaterial48 />,
    icon_dual_tone_molecular_infection_diagnostic_48:
  <IconDualToneMolecularInfectionDiagnostic48 />,
    icon_dual_tone_newbadge_48: <IconDualtoneNewbadge48 />,
    icon_dual_tone_parents_48: <IconDualToneParents48 />,
    icon_dual_tone_postbox_48: <IconDualTonePostbox48 />,
    icon_dual_tone_pricing_48: <IconDualTonePricing48 />,
    icon_dual_tone_profile_48: <IconDualToneProfile48 />,
    icon_dual_tone_publication_48: <IconDualTonePublication48 />,
    icon_dual_tone_register_48: <IconDualToneRegister48 />,
    icon_dual_tone_research_and_development_48: <IconDualToneResearchAndDevelopment48 />,
    icon_dual_tone_research_funding_48: <IconDualToneResearchFunding48 />,
    icon_dual_tone_research_scope_48: <IconDualToneResearchScope48 />,
    icon_dual_tone_resources_48: <IconDualToneResources48 />,
    icon_dual_tone_schools_48: <IconDualToneSchools48 />,
    icon_dual_tone_science_award_48: <IconDualToneScienceAward48 />,
    icon_dual_tone_scientific_posters_48: <IconDualToneScientificPosters48 />,
    icon_dual_tone_search_request_48: <IconDualToneSearchRequest48 />,
    icon_dual_tone_services_48: <IconDualToneServices48 />,
    icon_dual_tone_sports_clubs_48: <IconDualToneSportsClubs48 />,
    icon_dual_tone_standards_48: <IconDualToneStandards48 />,
    icon_dual_tone_students_48: <IconDualToneStudents48 />,
    icon_dual_tone_study_48: <IconDualToneStudy48 />,
    icon_dual_tone_support_programs_48: <IconDualToneSupportPrograms48 />,
    icon_dual_tone_tools_48: <IconDualToneTools48 />,
    icon_dual_tone_virtual_drive_48: <IconDualToneVirtualDrive48 />,
    icon_dual_tone_volunteers_48: <IconDualToneVolunteers48 />,
    icon_dual_tone_webform_48: <IconDualToneWebform48 />,
    icon_event_48: <IconEvent48 />,
    icon_founding_48: <IconFounding48 />,
    icon_gobal_donors_48: <IconGobalDonors48 />,
    icon_happy_patient_48: <IconHappyPatient48 />,
    icon_host_event_48: <IconHostEvent48 />,
    icon_local_donors_48: <IconLocalDonors48 />,
    icon_match_48: <IconMatch48 />,
    icon_play_48: <IconPlay48 />,
    icon_registration_48: <IconRegistration48 />,
    icon_registration_costs_48: <IconRegistrationCosts48 />,
    icon_registry_entry_48: <IconRegistryEntry48 />,
    icon_stem_cell_collection_48: <IconStemCellCollection48 />,
    icon_swabbing_48: <IconSwabbing48 />,
    icon_transplantation_48: <IconTransplantation48 />,

    icon_find_event_48: <IconDualToneEvent48 />,
    icon_dual_tone_donate_cl_48: <IconDualToneDonateUsCl48 />,
    icon_dual_tone_donate_us_48: <IconDualToneDonateUsCl48 />,
    icon_register_48: <IconDualToneRegister48 />,
    icon_virtual_drive_48: <IconDualToneInfluencer48 />,
    icon_profile_48: <IconDualToneProfile48 />,
    icon_donate_dollar_48: <IconDualToneDonateUsCl48 />,
    icon_donate_euro_48: <IconDualToneDonateDe48 />,
    icon_donate_pounds_48: <IconDualToneDonateUk48 />,
    icon_donate_rupie_48: <IconDualToneDonateIn48 />,
    icon_donate_zloty_48: <IconDualToneDonatePl48 />,

    // flags
    dkms_chile: <IconChile />,
    dkms_germany: <IconGermany />,
    dkms_poland: <IconPoland />,
    dkms_us: <IconUsa />,
    dkms_uk: <IconUk />,
    dkms_bmst_india: <IconIndia />,
    dkms_africa: <IconAfrica />,

    // africa payment provider
    zapper: <IconZapper18 />,
    snapscan: <IconSnapscan18 />,
  };

  if (icons[iconName]) {
    return icons[iconName];
  }

  return null;
};

export default iconHelper;

/* eslint-disable sort-keys,no-param-reassign */
import { dateFactory, format } from "../../utils/dateHelper";

export default class ProfileDataHelper {
  static requiredDataAvailable(profileData) {
    return !profileData.birthdate
      || !profileData.donorId
      || !profileData.street
      || !profileData.city
      || !profileData.houseno
      || !profileData.zipcode;
  }

  static getProfileInformation(profile, profileData) {
    profileData.gender = profile?.sex;
    profileData.salutation = profile?.salutation || "";
    profileData.title = profile?.title || "";
    profileData.firstname = profile?.firstname;
    profileData.lastname = profile?.lastname;
    profileData.houseno = profile?.houseno;
    profileData.street = profile?.street;
    profileData.zipcode = profile?.zipcode;
    profileData.city = profile?.city;
    profileData.co = profile?.co || "";
    profileData.apartment = profile?.apartment || "";
    profileData.email = profile?.email;
    profileData.mobile = profile?.mobile || "";
    profileData.alternatePhone = profile?.phone || "";
    profileData.donorId = profile?.donor_id;
    profileData.birthdate = profile?.birthdate;

    switch (process.env.GATSBY_SITE) {
      case "de":
        return ProfileDataHelper.#getProfileDataDe(profile);
      default:
        return {};
    }
  }

  static preparePayload(values, profileData, date) {
    let payload;

    switch (process.env.GATSBY_SITE) {
      case "de":
        payload = ProfileDataHelper.#getPostDataDe(values, profileData, date);
        break;
      default:
        payload = {};
    }

    return payload;
  }

  static getTitleOptions(site = process.env.GATSBY_SITE) {
    switch (site) {
      case "de":
      case "gb":
        return [
          { label: "Dr.", value: "Dr." },
          { label: "Dr. med.", value: "Dr. med." },
          { label: "Dr. med. dent.", value: "Dr. med. dent." },
          { label: "Dr. pharm.", value: "Dr. pharm." },
          { label: "Dr. rer. hum.", value: "Dr. rer. hum." },
          { label: "Prof.", value: "Prof." },
          { label: "Prof. Dr.", value: "Prof. Dr." },
        ];
      default: return [];
    }
  }

  static #getPostDataDe = (values, profileData, date) => ({
    former_address: {
      street: profileData.street,
      houseno: profileData.houseno,
      zipcode: profileData.zipcode,
      city: profileData.city,
      apartment: profileData.apartment,
      co: profileData.co,
    },
    person: {
      // todo: Check if the date conversion is necessary
      birthdate: format(dateFactory(profileData.birthdate), "yyyy-MM-dd"),
      title: values.title,
      salutation: values.salutation,
      firstname: values.firstname,
      lastname: values.lastname,
      donor_id: profileData.donorId,
    },
    private_address: {
      street: values.street,
      houseno: values.houseno,
      city: values.city,
      zipcode: values.zipcode,
      apartment: values.apartment,
      co: values.co,
    },
    private_contact: {
      mobile: values.mobile,
      phone: values.alternatePhone,
      email: values.email,
    },
    ciu_data: {
      validity_date: date,
    },
  });

  static #getProfileDataDe = (profile) => ({
    account: {
      password: "•••••••••••",
    },
    username: {
      email: profile.email,
    },
    person: {
      salutation: profile.salutation,
      title: profile.title || "",
      firstname: profile.firstname,
      lastname: profile.lastname,
    },
    private_address: {
      street: profile.street,
      houseno: profile.houseno,
      apartment: profile.apartment || "",
      co: profile.co || "",
      zipcode: profile.zipcode,
      city: profile.city,
    },
    phone: {
      mobile: profile.mobile || "",
      alternatePhone: profile.phone || "",
    },
  });
}

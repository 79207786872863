import { DataLayer as ProDataLayer } from "@piwikpro/react-piwik-pro";

class DataLayer {
  static currentStep = "undefined";

  static dialogType = "undefined";

  static events = {
    payment: "pp_donation_step_close",
    registration: "pp_registration_step_close",
  };

  static pushEvent = (eventName, options = null) => {
    if (typeof window !== "undefined") {
      ProDataLayer.push({
        event: eventName,
        ...options,
      });
    }
  };

  static unloadTracking = () => {
    ProDataLayer.push({
      event: DataLayer.events[DataLayer.dialogType],
      event_value: DataLayer.currentStep,
    });
  };

  static addUnloadListener = () => {
    if (typeof window !== "undefined" && window.dataLayer) {
      window.addEventListener("unload", DataLayer.unloadTracking, true);
    }
  };

  static removeUnloadListener = () => {
    if (typeof window !== "undefined" && window.dataLayer) {
      window.removeEventListener("unload", DataLayer.unloadTracking, true);
    }
  };
}

export default DataLayer;

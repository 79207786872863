/* eslint-disable */
// todo: fix lint
import React from "react";

import { Link } from "gatsby";
import Spinner from "react-bootstrap/Spinner";

import getInternalLinkById from "../../utils/getInternalLinkById";
import iconHelper from "../../utils/iconHelper";
import navigationLinkActions from "./navigationLinkActions";
import { graphql, useStaticQuery } from "gatsby";

const NavigationLink = (item) => {
  let external = false;
  let linkChildren = null;
  let href = null;

  const data = useStaticQuery(graphql`
    {
      siteSettings: kontentItemSiteSettings(system: {codename: {eq: "site_settings"}}) {
        elements {
          faq_page_navigation_item {
            value {
              id
            }
          }
        }
      }
      faqAnchorLinks: allKontentItemFaq {
        nodes {
          id
          system {
            id
          }
          elements {
            anchor_slug {
              value
            }
          }
        }
      }
    }
  `);

  if (item.elements) {
    linkChildren = item.elements.title.value;

    if (item.elements?.icon?.value.length > 0) {
      linkChildren = <>{iconHelper(item.elements.icon.value[0].codename)}{linkChildren}</>;
    }

    if (item.elements.external_url.value) {
      external = /^(http|\/\/)/.test(item.elements.external_url.value);
      href = item.elements.external_url.value;
    } else {
      href = item.id;
    }
  }

  let text = linkChildren || item.children;

  if (item.loading) {
    text = (<Spinner animation="border" variant="dark" />);
  }

  if (item.href) {
    external = /^(http|\/\/|mailto:|tel:)/.test(item.href);
    href = item.href;
  }

  if (!href) {
    const {
      typeOverwrite,
      loading,
      ...itemProps
    } = item;

    return (
      // eslint-disable-next-line react/button-has-type
      <button type={typeOverwrite || "button"} {...itemProps}>
        {text}
      </button>
    );
  }

  if (href.startsWith("action:")) {
    const { action, childrenOverwrite } = navigationLinkActions(href);

    if (action !== null) {
      return (
        <a
          href=""
          className={item.className}
          onMouseEnter={item.onMouseEnter}
          onClick={action}
          title={item.title}
        >
          {childrenOverwrite || text}
        </a>
      );
    }
  }

  if (!external) {
    let title = "";
    let link = getInternalLinkById(href);
    let faqAnchorLink = false;

    if (!link) {
      const faqLinks = data && data.faqAnchorLinks ? data.faqAnchorLinks.nodes : [];
      link = faqLinks.find((o) => o.system.id === href);

      if (link) {
        faqAnchorLink = true;
      }
    }

    if (link) {
      href = link.slug;
      title = item.title || link.title;

      if (faqAnchorLink && data.siteSettings?.elements?.faq_page_navigation_item?.value?.length > 0) {
        const faqPage = getInternalLinkById(data.siteSettings.elements.faq_page_navigation_item.value[0].id);

        href = faqPage?.slug ? faqPage.slug + "#" + link.elements.anchor_slug.value : link.slug;
      }
    } else {
      // todo: dont show id, if not found
      href = (/^(?!\/)/.test(href)) && !href.startsWith("#") ? `/${href}` : href;
      title = item.title || title;
    }

    if (item.target && !faqAnchorLink) {
      return (
        <a
          href={href}
          className={item.className}
          onMouseEnter={item.onMouseEnter}
          onClick={item.onClick}
          target={item.target}
          title={title}
          rel={item.target === "_blank" ? "noopener noreferrer" : null}
          id={item.idName}
        >
          {text}
        </a>
      );
    }

    return (
      <Link
        to={href}
        className={item.className}
        onMouseEnter={item.onMouseEnter}
        onClick={item.onClick}
        title={title}
        activeClassName={item.activeClassName !== undefined ? item.activeClassName : "active"}
        partiallyActive={item.partiallyActive ?? true}
        state={item.state}
        id={item.idName}
      >
        {text}
      </Link>
    );
  }

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={item.className}
      onMouseEnter={item.onMouseEnter}
      title={item.title}
      id={item.idName}
    >
      {text}
    </a>
  );
};

export default NavigationLink;

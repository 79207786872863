import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  excludedTopNotifications: [],
};

const NotificationSlice = createSlice({
  initialState,
  name: "notification",
  reducers: {
    excludeTopNotification: (state, { payload }) => {
      const notifications = state.excludedTopNotifications.filter(
        (item) => item.id !== payload.id,
      );
      notifications.push(payload);

      state.excludedTopNotifications = notifications;
    },
  },
});

export const {
  excludeTopNotification,
} = NotificationSlice.actions;

export default NotificationSlice.reducer;

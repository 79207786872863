import React from "react";

import formatFns from "date-fns/format";
import {
  de,
  enGB,
  enIN,
  enUS,
  es,
  pl,
} from "date-fns/locale";
import parseFns from "date-fns/parse";
import { formatInTimeZone as formatInTimeZoneFns } from "date-fns-tz";

const localeKey = (process.env.GATSBY_LANGUAGES ?? "en-us")
  .split(",")
  .shift()
  .toLowerCase();

let locale;

// Fixme: Only import date-fns locale needed for current env
switch (localeKey) {
  case "en-za":
  case "en-gb":
    locale = enGB;
    break;
  case "en-in":
    locale = enIN;
    break;
  case "es-cl":
    locale = es;
    break;
  case "pl-pl":
    locale = pl;
    break;
  case "en-us":
    locale = enUS;
    break;
  case "de-de":
  default:
    locale = de;
}

const localeFormats = () => {
  switch (localeKey) {
    case "en-za":
    case "en-gb":
    case "en-in":
    case "es-cl":
      return {
        dateParts: ["dd", "MM", "YYYY"],
        delimiter: "/",
      };
    case "en-us":
      return {
        dateParts: ["MM", "dd", "YYYY"],
        delimiter: "/",
      };
    case "pl-pl":
    case "de-de":
    default:
      return {
        dateParts: ["dd", "MM", "YYYY"],
        delimiter: ".",
      };
  }
};

const format = (
  date = new Date(),
  formatString = "P",
  options = {},
) => (
  formatFns(
    date,
    formatString,
    {
      locale,
      ...options,
    },
  )
);

const formatInTimeZone = (
  date = new Date(),
  formatString = "P",
  timezone,
  options = {},
) => (
  formatInTimeZoneFns(
    date,
    timezone,
    formatString,
    {
      locale,
      ...options,
    },
  )
);

const DisplayDate = (
  dateString,
  timezone = null,
  formatString = "P",
  machineReadableFormatString = "yyyy-MM-dd",
  options = {},
) => {
  const date = new Date(dateString);

  const machineReadable = formatInTimeZoneFns(
    date,
    timezone || process.env.GATSBY_TIMEZONE,
    machineReadableFormatString,
    {
      locale,
      ...options,
    },
  );

  return (
    <time dateTime={machineReadable}>
      {formatInTimeZoneFns(
        date,
        timezone || process.env.GATSBY_TIMEZONE,
        formatString,
        {
          locale,
          ...options,
        },
      )}
    </time>
  );
};

const parse = (
  dateString,
  formatString = "P",
  referenceDate = new Date(),
  options = {},
) => (
  parseFns(
    dateString,
    formatString,
    referenceDate,
    {
      locale,
      ...options,
    },
  )
);

const dateFactory = (dateString) => (
  new Date(dateString + (dateString.length === 10 ? "T00:00:00" : ""))
);

export {
  format,
  formatInTimeZone,
  parse,
  localeFormats,
  dateFactory,
  DisplayDate,
};

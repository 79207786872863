import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  complianceTypes: [],
  consents: {},
  error: {},
  externalContentEnabled: false,
  loading: false,
  show: false,
};

export const getComplianceTypes = createAsyncThunk(
  "complianceConsent/getComplianceTypes",
  async (args, { fulfillWithValue, rejectWithValue }) => {
    const { ppms } = window;

    if (ppms) {
      return new Promise((resolve) => {
        ppms.cm.api(
          "getComplianceTypes",
          (response) => {
            resolve(fulfillWithValue(response));
          },
          (error) => {
            resolve(rejectWithValue(error));
          },
        );
      });
    }

    return rejectWithValue({ error: "PPMS undefined" });
  },
);

export const getComplianceSettings = createAsyncThunk(
  "complianceConsent/getComplianceSettings",
  async (args, { fulfillWithValue, rejectWithValue }) => {
    const { ppms } = window;

    if (ppms) {
      return new Promise((resolve) => {
        ppms.cm.api(
          "getComplianceSettings",
          (response) => {
            resolve(fulfillWithValue(response));
          },
          (error) => {
            resolve(rejectWithValue(error));
          },
        );
      });
    }

    return rejectWithValue({ error: "PPMS undefined" });
  },
);

export const setComplianceSettings = createAsyncThunk(
  "complianceConsent/setComplianceSettings",
  async (args, { fulfillWithValue, rejectWithValue }) => {
    const { ppms } = window;

    if (ppms) {
      return new Promise((resolve) => {
        ppms.cm.api(
          "setComplianceSettings",
          args,
          (response) => {
            resolve(fulfillWithValue(response));
          },
          (error) => {
            resolve(rejectWithValue(error));
          },
        );
      });
    }

    return rejectWithValue({ error: "PPMS undefined" });
  },
);

const ComplianceConsentSlice = createSlice({
  extraReducers: (builder) => {
    // getComplianceTypes
    builder.addCase(getComplianceTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getComplianceTypes.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.complianceTypes = payload;
    });
    builder.addCase(getComplianceTypes.rejected, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    });

    // getComplianceSettings
    builder.addCase(getComplianceSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getComplianceSettings.fulfilled, (state, { payload: { consents } }) => {
      state.loading = false;
      state.externalContentEnabled = consents && consents.custom_consent && typeof consents.custom_consent.status !== "undefined"
        ? consents.custom_consent.status === 1
        : false;
      state.consents = consents;
    });
    builder.addCase(getComplianceSettings.rejected, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    });

    // setComplianceSettings
    builder.addCase(setComplianceSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setComplianceSettings.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(setComplianceSettings.rejected, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    });
  },
  initialState,
  name: "complianceConsent",
  reducers: {
    setShowComplianceSettings: (state, { payload }) => {
      state.show = payload;
    },
  },
});

export const {
  setShowComplianceSettings,
} = ComplianceConsentSlice.actions;

export default ComplianceConsentSlice.reducer;

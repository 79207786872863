import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { setUrlURN } from "../../features/trackingSlice";

const UrlParamListener = ({ location }) => {
  const urlURN = useSelector((state) => state.tracking.urlURN);
  const dispatch = useDispatch();

  if (location.search && location.search !== "") {
    const searchItems = decodeURIComponent(location.search)
      .substring(1)
      .split("&");
    let urnParam = null;
    const urnPattern = /^urn:dkms(io)?:[a-zA-Z0-9-]+:[a-zA-Z0-9-]+:[A-Z_]+:[a-z0-9-]+:[a-zA-Z0-9_/-]+$/;

    searchItems.forEach((item) => {
      const pair = item.split("=");
      switch (pair[0].toLowerCase()) {
        case "urn":
          if (urnPattern.test(pair[1])) {
            // eslint-disable-next-line prefer-destructuring
            urnParam = pair[1];
          }
          break;
        default:
      }
    });

    if (urnParam && urnParam !== urlURN) {
      dispatch(
        setUrlURN(urnParam),
      );
    }
  }

  return null;
};

UrlParamListener.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default UrlParamListener;

import { createSlice } from "@reduxjs/toolkit";

import DataLayer from "../utils/dataLayer";

const initialState = {
  URN: null,
  secondaryURN: null,
  urlURN: null,
};

const TrackingSlice = createSlice({
  initialState,
  name: "tracking",
  reducers: {
    setSecondaryURN: (state, { payload }) => {
      if (payload) {
        state.secondaryURN = payload;
      }
    },
    setTracking: (state, { payload }) => {
      if (payload.URN && payload.URN !== "") {
        DataLayer.pushEvent("ppd_urn", { dimension_urn_storage: payload.URN });
        state.URN = payload.URN;
        state.secondaryURN = payload.secondaryURN;
      }
    },
    setURN: (state, { payload }) => {
      if (payload) {
        DataLayer.pushEvent("ppd_urn", { dimension_urn_storage: payload });
        state.URN = payload;
      }
    },
    setUrlURN: (state, { payload }) => {
      if (payload) {
        DataLayer.pushEvent("ppd_url_urn", { dimension_urn_storage: payload });
        state.urlURN = payload;
      }
    },
  },
});

export const {
  setSecondaryURN,
  setTracking,
  setURN,
  setUrlURN,
} = TrackingSlice.actions;

export default TrackingSlice.reducer;

import React from "react";

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import { useDispatch, useSelector } from "react-redux";

import getInternalLinkById from "./getInternalLinkById";
import { ROLE_DONOR, ROLE_KIT_REQUESTER } from "./getUserRole";
import IconButton from "../components/button/iconButton";
import ProfileData from "../components/ssp/profileData";
import ProfileDataHelper from "../components/ssp/profileDataHelper";
import profileValidationSchema from "../components/ssp/profileValidationSchema";
import {
  useGetProfileContactQuery,
  useGetProfileInfoQuery,
  useGetUserAddressQuery,
} from "../features/io/ioSspApiSlice";
import { setIsEditing } from "../features/sspSlice";
import { IconAccordionExpand18 } from "../icons";

const DAY_LIMIT_KIT_NOT_YET_RETURNED = 14;
const DAY_LIMIT_NEW_DONOR_DONATION = 3;
const DAY_LIMIT_MISSING_MANDOTARY_DATA = 1;
const DAY_LIMIT_DATA_UP_TO_DATE = 180;
const DAY_LIMIT_MISSING_OPTIONAL_DATA = 7;

const CATEGORY_A = "a";
const CATEGORY_B = "b";
const CATEGORY_C = "c";

export const CATEGORY_A1 = "kitNotYetReturned";
export const CATEGORY_B1 = "newDonorDonation";
export const CATEGORY_C1 = "missingMandatoryData";
export const CATEGORY_C2 = "dataUpToDate";
export const CATEGORY_C3 = "missingOptionalData";

const getDaysInMs = (days) => (days * 24 * 60 * 60 * 1000);

const calculateDate = (date) => new Date() - new Date(date);

const getFields = (profileInfo, value, url = null, callback = null) => {
  const validationSchema = profileValidationSchema(useIntl());

  const excludedFields = ["salutation", "title", "apartment", "co"];

  const profileData = JSON.parse(sessionStorage.getItem("sessionProfileData")) || new ProfileData();

  const profile = JSON.parse(sessionStorage.getItem("sessionProfile")) || ProfileDataHelper.getProfileInformation(profileInfo, profileData);

  const emptyData = [];
  Object.keys(profile).forEach((item) => {
    Object.keys(profile[item]).forEach((key) => {
      if (!(excludedFields.indexOf(key) !== -1) && !profile[item][key]
        && validationSchema.describe().fields?.[key]?.optional === value
        // currently only show one missing element
        && emptyData.length === 0) {
        emptyData.push(callback(item, key, url));
      }
    });
  });

  return emptyData;
};

const getLink = (item, key, url) => {
  const dispatch = useDispatch();

  return (
    <IconButton
      variant="text-primary"
      href={`${getInternalLinkById(url).slug}#${item}`}
      key={key}
      onClick={() => {
        dispatch(
          setIsEditing(item),
        );
      }}
    >
      <IconAccordionExpand18 /> <FormattedMessage id={`profile.${key}.label`} />
    </IconButton>
  );
};

const getActionPrompts = (role, statusInfo = null, urls) => {
  const actionPromptData = useSelector((state) => state.actionPrompt.actionPromptData);

  const userAddress = useGetUserAddressQuery();
  const profileInfo = useGetProfileInfoQuery();
  const profileContact = useGetProfileContactQuery();

  if (!userAddress.data && !profileInfo.data && !profileContact.data) {
    return [];
  }

  const usedGroups = [];

  const data = { ...userAddress.data, ...profileInfo.data, ...profileContact.data };

  const actionPrompts = [];

  if (role && statusInfo) {
    if (role === ROLE_KIT_REQUESTER) {
      /**
       * Wenn Probe versendet wurde aber vom Spender nicht zurückgesandt wurde
       */
      if (usedGroups.indexOf(CATEGORY_A) === -1
        && !statusInfo.sample_returned && statusInfo.sample_provided) {
        /**
         * Wenn der versand der Probe schon länger als x Tage ist
         */
        if (calculateDate(statusInfo.sample_provided)
          > getDaysInMs(DAY_LIMIT_KIT_NOT_YET_RETURNED)) {
          /**
           * Überprüfung ob in der Session nicht vorhanden ist oder
           * wenn die letzte Meldung länger als x Tage schon vergangen ist
           */
          if (!actionPromptData[CATEGORY_A1]
            || calculateDate(actionPromptData[CATEGORY_A1])
            > getDaysInMs(DAY_LIMIT_KIT_NOT_YET_RETURNED)) {
            actionPrompts.push({ category: CATEGORY_A1, links: [] });
            usedGroups.push(CATEGORY_A);
          }
        }
      }
    } else if (role === ROLE_DONOR) {
      /**
       * Wenn die Probe zurückgesandt wurde
       */
      if (usedGroups.indexOf(CATEGORY_B) === -1 && statusInfo.sample_returned) {
        /**
         * Wenn der Rückversand der Probe schon länger als x Tage ist
         */
        if (calculateDate(statusInfo.sample_returned) > getDaysInMs(DAY_LIMIT_NEW_DONOR_DONATION)) {
          /**
           * Überprüfung ob in der Session nicht vorhanden ist oder
           * wenn die letzte Meldung länger als x Tage schon vergangen ist
           */
          if (!actionPromptData[CATEGORY_B1]
            || calculateDate(actionPromptData[CATEGORY_B1])
            > getDaysInMs(DAY_LIMIT_NEW_DONOR_DONATION)) {
            actionPrompts.push({ category: CATEGORY_B1, links: [] });
            usedGroups.push(CATEGORY_B);
          }
        }
      }

      const emptyRequiredFields = getFields(data, false, urls.profile, getLink);

      /**
       * Fehlen Pflichtfelder
       */
      if (usedGroups.indexOf(CATEGORY_C) === -1 && emptyRequiredFields.length > 0) {
        /**
         * Überprüfung ob in der Session nicht vorhanden ist oder
         * wenn die letzte Meldung länger als x Tage schon vergangen ist
         */
        if (!actionPromptData[CATEGORY_C1]
          || calculateDate(actionPromptData[CATEGORY_C1])
          > getDaysInMs(DAY_LIMIT_MISSING_MANDOTARY_DATA)) {
          actionPrompts.push({ category: CATEGORY_C1, links: emptyRequiredFields });
          usedGroups.push(CATEGORY_C);
        }
      }

      /**
       * Wenn die Probe zurückgesandt wurde
       */
      if (usedGroups.indexOf(CATEGORY_C) === -1 && statusInfo.sample_returned) {
        /**
         * Wenn der Rückversand der Probe schon länger als x Tage ist
         */
        if (calculateDate(statusInfo.sample_returned) > getDaysInMs(DAY_LIMIT_DATA_UP_TO_DATE)) {
          /**
           * Überprüfung ob in der Session nicht vorhanden ist oder
           * wenn die letzte Meldung länger als x Tage schon vergangen ist
           */
          if (!actionPromptData[CATEGORY_C2]
            || calculateDate(actionPromptData[CATEGORY_C2])
            > getDaysInMs(DAY_LIMIT_DATA_UP_TO_DATE)) {
            actionPrompts.push({ category: CATEGORY_C2, links: [] });
            usedGroups.push(CATEGORY_C);
          }
        }
      }

      const emptyOptionalFields = getFields(data, true, urls.profile, getLink);

      /**
       * Fehlen optionale Angaben
       */
      if (usedGroups.indexOf(CATEGORY_C) === -1 && emptyOptionalFields.length > 0) {
        /**
         * Überprüfung ob in der Session nicht vorhanden ist oder
         * wenn die letzte Meldung länger als x Tage schon vergangen ist
         */
        if (!actionPromptData[CATEGORY_C3]
          || calculateDate(actionPromptData[CATEGORY_C3])
          > getDaysInMs(DAY_LIMIT_MISSING_OPTIONAL_DATA)) {
          actionPrompts.push({ category: CATEGORY_C3, links: emptyOptionalFields });
          usedGroups.push(CATEGORY_C);
        }
      }
    }
  }

  return actionPrompts;
};

export default getActionPrompts;

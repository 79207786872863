import getSitePages from "./getSitePages";

const getInternalLinkById = (id) => {
  const { links } = getSitePages();

  const link = links.nodes.find(
    (o) => o.nodeId === id
      || o.parentId === id
      || o.systemId === id,
  );

  if (link) {
    return {
      nodeId: link.nodeId,
      parentId: link.parentId,
      slug: link.slug,
      systemId: link.systemId,
      title: link.title,
    };
  }

  return null;
};

export default getInternalLinkById;

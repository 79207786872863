import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentRegion: "",
  detailsView: false,
  fields: [],
  formValues: {},
  mailUpdateFields: [],
  mailUpdateFormValues: {},
  prevAddressCheckboxChecked: false,
  prevRegion: "",
  titleUK: "",
};

const UpdateAddressSlice = createSlice({
  initialState,
  name: "updateAddress",
  reducers: {
    reset: () => initialState,
    setCurrentRegion: (state, { payload }) => {
      state.currentRegion = payload;
    },
    setDetailsView: (state, { payload }) => {
      state.detailsView = payload;
    },
    setFields: (state, { payload }) => {
      state.fields = payload;
    },
    setFormValues: (state, { payload }) => {
      state.formValues = payload;
    },
    setMailUpdateFields: (state, { payload }) => {
      state.mailUpdateFields = payload;
    },
    setMailUpdateFormValues: (state, { payload }) => {
      state.mailUpdateFormValues = payload;
    },
    setPrevAddressCheckboxChecked: (state, { payload }) => {
      state.prevAddressCheckboxChecked = payload;
    },
    setPrevRegion: (state, { payload }) => {
      state.prevRegion = payload;
    },
    setTitleUK: (state, { payload }) => {
      state.titleUK = payload;
    },
  },
});

export const {
  reset,
  setCurrentRegion,
  setDetailsView,
  setFields,
  setFormValues,
  setPrevAddressCheckboxChecked,
  setPrevRegion,
  setTitleUK,
  setMailUpdateFormValues,
  setMailUpdateFields,
} = UpdateAddressSlice.actions;

export default UpdateAddressSlice.reducer;

/* eslint-disable sort-keys */
import {
  CATEGORY_A1,
  CATEGORY_B1,
  CATEGORY_C1,
  CATEGORY_C2,
  CATEGORY_C3,
} from "../../utils/getActionPrompts";

export default class ActionPromptData {
  [CATEGORY_A1] = "";

  [CATEGORY_B1] = "";

  [CATEGORY_C1] = "";

  [CATEGORY_C2] = "";

  [CATEGORY_C3] = "";
}

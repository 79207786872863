import { graphql, useStaticQuery } from "gatsby";

const getSitePages = () => useStaticQuery(graphql`
    {
      links: allInternalLinks {
        nodes {
          id
          nodeId
          parentId
          slug
          systemId
          title
        }
      }
    }
  `);

export default getSitePages;

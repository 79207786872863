import { createSlice } from "@reduxjs/toolkit";

import ActionPromptData from "../components/ssp/actionPromptData";

const initialState = {
  actionPromptData: new ActionPromptData(),
};

const ActionPromptSlice = createSlice({
  initialState,
  name: "actionPrompt",
  reducers: {
    setActionPromptData: (state, { payload }) => {
      state.actionPromptData = {
        ...state.actionPromptData,
        ...payload,
      };
    },
  },
});

export const {
  setActionPromptData,
} = ActionPromptSlice.actions;

export default ActionPromptSlice.reducer;

export const ROLE_USER = "user";
export const ROLE_KIT_REQUESTER = "kit_requester";
export const ROLE_DONOR = "donor";
export const ROLE_PAUSED = "paused";

const getUserRole = (userInfo) => {
  if (userInfo?.rol.indexOf("DONOR") !== -1) {
    return ROLE_DONOR;
  }

  if (userInfo?.rol.indexOf("KIT_REQUESTER") !== -1) {
    return ROLE_KIT_REQUESTER;
  }

  return ROLE_USER;
};

export default getUserRole;

/* eslint-disable sort-keys */
export default class SetupData {
  motivation = "";

  relationship = "";

  relationshipLabel = "";

  driveType = "";

  driveTypeValue = "";

  driveTitle = "";

  motivationForDrive = "";

  organizationName = "";

  location = "";

  website = "";

  socialMedia = false;

  facebook = "";

  twitter = "";

  instagram = "";

  numberPersons = "";

  lastname = "";

  firstname = "";

  email = "";

  phone = "";

  motivationValues = {
    patient: "I want to support a specific patient",
    mission: "I want to support the general mission",
  }
}

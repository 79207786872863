import { Subject } from "rxjs";
import { filter } from "rxjs/operators";

const notificationSubject = new Subject();
const defaultId = "default-notification";

export const notificationType = {
  error: "error",
  info: "info",
  success: "success",
  warning: "warning",
};

function clear(id = defaultId) {
  notificationSubject.next({ id });
}

function notification(notify) {
  const tmpNotification = notify;
  tmpNotification.id = notify.id || defaultId;
  notificationSubject.next(tmpNotification);
}

function error(message, options) {
  notification({ ...options, message, type: notificationType.error });
}

function info(message, options) {
  notification({ ...options, message, type: notificationType.info });
}

function onNotification(id = defaultId) {
  return notificationSubject.asObservable().pipe(filter((x) => x && x.id === id));
}

function success(message, options) {
  notification({ ...options, message, type: notificationType.success });
}

function warn(message, options) {
  notification({ ...options, message, type: notificationType.warning });
}

export const notificationService = {
  clear,
  error,
  info,
  notification,
  onNotification,
  success,
  warn,
};

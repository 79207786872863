import * as Sentry from "@sentry/gatsby";

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";

Sentry.init({
  dsn: process.env.SENTRY,
  environment: activeEnv,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  release: process.env.GATSBY_SENTRY_RELEASE,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  telemetry: false,
  tracesSampleRate: 1.0,
});

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEditing: [],
  pseudoLoggedOut: false,
};

const SspSlice = createSlice({
  initialState,
  name: "ssp",
  reducers: {
    clearIsEditing: (state) => {
      state.isEditing = initialState.isEditing;
    },
    pseudoLogout: (state, { payload }) => {
      state.pseudoLoggedOut = payload;

      // Remove cached user Data on logout/login
      state.isEditing = initialState.isEditing;
      sessionStorage.removeItem("sessionProfileData");
      sessionStorage.removeItem("sessionProfile");
    },
    setIsEditing: (state, { payload }) => {
      const tmpIsEditing = [].concat(state.isEditing);
      const indexOfRemove = tmpIsEditing.indexOf(payload);

      if (indexOfRemove !== -1) {
        tmpIsEditing.splice(indexOfRemove, 1);
      } else {
        tmpIsEditing.push(payload);
      }

      state.isEditing = tmpIsEditing;
    },
    setIsEditingForFailedSegments: (state, { payload }) => {
      if (Array.isArray(payload)) {
        const forceEditingItems = payload.filter((item) => state.isEditing.indexOf(item) === -1);

        if (forceEditingItems.length > 0) {
          state.isEditing = [...state.isEditing, ...forceEditingItems];
        }
      }
    },
  },
});

export const {
  clearIsEditing,
  pseudoLogout,
  setIsEditing,
  setIsEditingForFailedSegments,
} = SspSlice.actions;

export default SspSlice.reducer;

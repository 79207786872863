const copyToClipboard = (text, callback = null) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      if (callback !== null) {
        callback();
      }
    });
};

export default copyToClipboard;

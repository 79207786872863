export const cleanupObject = (object) => {
  Object
    .entries(object)
    .forEach(([k, v]) => {
      if (v && typeof v === "object") {
        cleanupObject(v);
      }

      if (
        (v && typeof v === "object" && !Object.keys(v).length)
        || v === null || v === undefined || v === ""
      ) {
        if (Array.isArray(object)) {
          object.splice(k, 1);
        } else {
          // eslint-disable-next-line no-param-reassign
          delete object[k];
        }
      }
    });

  return object;
};

export const trimObjectValues = (values) => {
  const tmpValues = { ...values };
  Object.keys(tmpValues).forEach((k) => {
    tmpValues[k] = typeof tmpValues[k] === "string" ? tmpValues[k].trim() : tmpValues[k];
  });

  return tmpValues;
};

import { ROLE_DONOR, ROLE_KIT_REQUESTER, ROLE_USER } from "./getUserRole";

export const UNDEFINED_ROLE = "undefined_role";
export const STATE_OK = "ok";
export const STATE_ERROR = "error";
export const STATE_REGISTERED = "registered";
export const STATE_REGISTERED_ERROR = "registered_error";
export const STATE_RETURNED = "returned";
export const STATE_RETURNED_ERROR = "returned_error";
export const STATE_SHIPPED = "shipped";
export const STATE_SHIPPED_ERROR = "shipped_error";
export const STATE_ORDERED = "ordered";
export const STATE_ORDERED_ERROR = "ordered_error";
export const ERROR_STATES = [
  STATE_ERROR,
  STATE_SHIPPED_ERROR,
  STATE_REGISTERED_ERROR,
  STATE_RETURNED_ERROR,
  STATE_ORDERED_ERROR,
];

export const getRegState = (userStatusInfo, role) => {
  switch (role) {
    case ROLE_DONOR:
      if (userStatusInfo?.consentform_signed
        && userStatusInfo?.sample_typed) {
        return userStatusInfo?.status === "ok" ? STATE_REGISTERED : STATE_REGISTERED_ERROR;
      }

      return STATE_REGISTERED_ERROR;
    case ROLE_KIT_REQUESTER:
      if (userStatusInfo?.swabkit_ordered && userStatusInfo?.sample_provided
        && userStatusInfo?.sample_laboratory_entry) {
        return userStatusInfo?.status === "ok" ? STATE_RETURNED : STATE_RETURNED_ERROR;
      }

      if (userStatusInfo?.swabkit_ordered && userStatusInfo?.sample_provided) {
        return userStatusInfo?.status === "ok" ? STATE_SHIPPED : STATE_SHIPPED_ERROR;
      }

      if (userStatusInfo?.swabkit_ordered) {
        return userStatusInfo?.status === "ok" ? STATE_ORDERED : STATE_ORDERED_ERROR;
      }

      return STATE_ERROR;
    case ROLE_USER:
      return STATE_OK;
    default:
      return UNDEFINED_ROLE;
  }
};
export const hasRegStateError = (regState) => ERROR_STATES.indexOf(regState) !== -1;

import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const axiosBaseQuery = ({ baseUrl } = { baseUrl: "" }) => (
  async ({
    url,
    method = "GET",
    data,
    params,
    headers,
  }) => {
    try {
      const result = await axios({
        data,
        headers,
        method,
        params,
        url: `${baseUrl + url}/${process.env.GATSBY_ADDSEARCH_SITE_KEY}`,
      });

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError;

      return {
        error: {
          data: err.response?.data || err.message,
          status: err.response?.status,
        },
      };
    }
  }
);

const AddSearchApiSlice = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: "https://api.addsearch.com/v1/",
  }),
  endpoints: (builder) => ({
    reportClick: builder.mutation({
      query: ({ keyword, id, position }) => ({
        data: {
          action: "click",
          docid: id,
          keyword,
          position,
        },
        method: "POST",
        url: "stats",
      }),
    }),
    search: builder.query({
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page > 1) {
          currentCache.hits.push(...newItems.hits);

          return currentCache;
        }

        return newItems;
      },
      query: ({ term, limit, page = 1 }) => ({
        params: {
          categories: process.env.GATSBY_ADDSEARCH_SEARCH_CATEGORY,
          fuzzy: "auto",
          limit,
          page,
          term,
        },
        url: "search",
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => (
        endpointName + queryArgs.term
      ),
    }),
  }),
  reducerPath: "addSearchApi",
});

export const {
  useSearchQuery,
  useReportClickMutation,
} = AddSearchApiSlice;

export default AddSearchApiSlice;

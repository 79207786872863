import Cookies from "js-cookie";

const isAuthenticated = () => {
  try {
    const cookie = Cookies.get("DKMSIOUserInfo");

    if (cookie !== "undefined") {
      const userInfo = JSON.parse(cookie);

      return new Date() <= new Date(userInfo?.exp);
    }
  } catch (e) {
    //
  }

  return false;
};

const getUserInfo = () => {
  let data;

  try {
    const cookie = Cookies.get("DKMSIOUserInfo");
    data = typeof cookie !== "undefined" ? JSON.parse(cookie) : null;
  } catch (e) {
    data = null;
  }

  return data;
};

export {
  isAuthenticated,
  getUserInfo,
};

import * as Yup from "yup";

export const YupSchemaTypes = Object.freeze({
  required: "required",
});

export const RegexPatterns = Object.freeze({
  email: /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+([a-zA-Z0-9]{2,})$/,
  noNumbers: /[^0-9]/,
  onlyLetters: /[a-zA-Z]/,
  onlyLettersAndNumbers: /[a-zA-Z0-9]/,
  onlyNumbers: /[0-9]/,
});

export const getYupSchemaValidator = (validator, type, msg) => {
  switch (validator) {
    case YupSchemaTypes.required:
      return Yup.string().required(msg);
    default:
      // eslint-disable-next-line no-console
      console.log("Unknown Yup schema validator");

      return null;
  }
};

const test = (pattern) => (e) => {
  e.persist();
  const charCode = (typeof e.which === "undefined") ? e.keyCode : e.which;
  const charStr = String.fromCharCode(charCode);

  if (pattern.test(charStr)) {
    return true;
  }
  e.preventDefault();

  return false;
};

export const getOnPreventKeypress = (pattern) => test(pattern);
